// Chakra imports
import { ArrowBackIcon, LockIcon } from "@chakra-ui/icons";
import {
    Box,
    Button,
    Flex,
    Grid,
    Icon,
    Image,
    IconButton,
    Progress,
    SimpleGrid,
    Spacer,
    Select,
    StatHelpText,
    StatLabel,
    StatNumber,
    Table,
    Tooltip, LinkBox, LinkOverlay,
    Text,
    Th, CircularProgress,
    Thead,
    Tr, AlertDialog,
    AlertDialogBody,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    useColorMode,
    useColorModeValue,
} from "@chakra-ui/react";
// assets
import peopleImage from "assets/img/people-image.png";
import logoChakra from "assets/svg/logo-white.svg";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// Custom icons
import DashboardTableRow from "components/Tables/DashboardTableRow";
import TimelineRow from "components/Tables/TimelineRow";
import React, { useState, useEffect } from "react";
import { AiOutlineRead } from "react-icons/ai";
import { BiBriefcase, BiTimeFive } from "react-icons/bi";
import { IoCheckmarkDoneCircleSharp, IoTime, IoTimerOutline, IoVideocamOutline } from "react-icons/io5";
import api from '../../helpers/Fetch';
import utils from '../../helpers/utils';
import lockIcon from 'assets/img/lock.png';

export default function Chapters(props) {
    const value = "$100.000";
    // Chakra Color Mode
    const [licInfo, setLicInfo] = useState(null);
    const [isAlertOpen, showAlert] = useState(false);
    const [data, setData] = useState(null);
    const [lang, setLang] = useState(localStorage.getItem('defaultLang'));
    let API = new api();
    let Util = new utils()
    const langRef = React.useRef(null);
    useEffect(() => {
        getLicenceInfo(localStorage.getItem('defaultLang'));
    }, [])
    const getLicenceInfo = (lang) => {
        API.getLicenceInfo({ lang: lang }).then((res) => {
            console.log(res.data);
            setLicInfo(res.data.data)
            getChapters(lang || res.data.data.languageSupport[0])
        }).then((err) => {
            console.log(err);
            Util.showServerError(err);
        })
    }
    const getChapters = (lang) => {
        API.getChapters({ lang: lang }).then((res) => {
            console.log(res.data);
            setData(res.data.data);
        }).then((err) => {
            console.log(err);
            Util.showServerError(err);
        })
    }
    const startQuiz = (id) => {
        // alert(id);
        localStorage.setItem('quiz_chapter_id', id);
        props.history.push('/run/quiz');
    }
    const openTopics = (id, name) => {
        localStorage.setItem('cdata', JSON.stringify({
            lang: langRef.current.value,
            moduleId: id,
            name: name
        }))
        props.history.push({
            pathname: '/t/view',
            state: null
        })
    }


    return (
        <Flex flexDirection="column" pt={{ base: "20px", md: "15px" }}>
            <Flex justify='space-between'>
                <Flex>
                    <IconButton aria-label='full screen' variant='link' icon={<ArrowBackIcon />} onClick={() => props.history.goBack()} />

                    <Text fontSize='large' fontWeight='semibold' m='3'>Modules</Text></Flex>
                <Select ref={langRef} w='150px' variant='filled' bg='white' color='gray.600' value={lang} size='sm' boxShadow='md' onChange={(e) => {
                    localStorage.setItem('defaultLang', e.target.value);
                    setLang(e.target.value);
                    getChapters(e.target.value);
                }}>
                    {!!licInfo && licInfo.languageSupport.map(item => {
                        return (<>
                            <option value={item.toLowerCase()}>{item.toUpperCase()}</option>
                        </>);
                    })}
                </Select>

            </Flex>
            {!!data ?
                <SimpleGrid columns={{ sm: 1, md: 4, xl: 5 }} spacing="24px">
                    {!!data && data.map(item => {
                        return (
                            <Flex direction='column'>
                                <LinkBox rounded='sm'>
                                    <LinkOverlay href='' onClick={(e) => {
                                        if (item.isLocked === false)
                                            openTopics(item.moduleId, item.title)
                                        else {
                                            e.preventDefault()
                                            showAlert(true);
                                        }
                                    }}>
                                        <Tooltip label={item.isLocked ? "Locked" : ""} hasArrow placement='top'>
                                            <Box _hover={{ mt: '-8px' }} transition="all .2s ease">
                                                <Card maxHeight="290.5px" p="1rem" bgColor={item.isLocked ? "red.100" : "green.100"}>
                                                    <CardBody
                                                        p="0px"
                                                        h={{ sm: "200px", lg: "100%" }}
                                                        borderRadius="1px"
                                                    >
                                                        <Flex
                                                            flexDirection="column"
                                                            color="black"
                                                            lineHeight="1.6"
                                                            w='100%'
                                                            ps='2'
                                                        >
                                                            <Text fontSize="md" fontWeight="bold" noOfLines={2}>
                                                                {item.title}
                                                            </Text>
                                                            <Text fontSize="sm" fontWeight="normal" pb=".3rem" noOfLines={2}>
                                                                {item.subtitle}
                                                            </Text>
                                                            <Flex align='center'>
                                                                <Icon as={BiTimeFive} h={'20px'} w={'20px'} color='black' />
                                                                <Text fontSize='sm' ms='1'>{(item.attemptedReadTime / 60).toFixed(1)} mins / {(item.totalReadTime / 60).toFixed(1)} mins</Text>
                                                            </Flex>
                                                            <Flex justify='space-between'>
                                                                {item.videos ?
                                                                    <Flex fontSize='sm' align='center' mt='2'>
                                                                        <Icon as={IoVideocamOutline} me='1' h={'20px'} w={'20px'} color='black' />
                                                                        <Text>Videos</Text>
                                                                    </Flex>
                                                                    : null}
                                                                {item.icc ?
                                                                    <Flex fontSize='sm' align='center' mt='2'>
                                                                        <Icon as={BiBriefcase} me='1' h={'18px'} w={'18px'} color='black' />
                                                                        <Text fontSize='sm' color='gray.600'>ICC</Text>
                                                                    </Flex> : null}
                                                            </Flex>

                                                            <Box borderRadius='10px' overflow='hidden' mt='5'>
                                                                <Progress colorScheme='green' size='sm' value={(item.attemptedReadTime / item.totalReadTime) * 100} />
                                                            </Box>
                                                            <Spacer />
                                                            <Flex
                                                                align="center"
                                                                justify='flex-end'
                                                                w='100%'
                                                            >

                                                            </Flex>
                                                        </Flex>
                                                    </CardBody>
                                                </Card>
                                            </Box>
                                        </Tooltip>
                                    </LinkOverlay>
                                </LinkBox>
                                {item.showQuiz || !!item.quizScore ?
                                    <Box w='100%' h='140px' bg='gray.100' pt='10' mt='-8' borderBottomLeftRadius="10px" borderBottomRightRadius="10px">
                                        {item.showQuiz ?
                                            <Button w='100%' variant='link' colorScheme='green' onClick={() => { startQuiz(item.moduleId) }}>{item.quizScore === null ? "Take Quiz" : "Retake"}</Button>
                                            : null}
                                        <Flex direction='column'>
                                            {item.quizScore !== null ?
                                                <Flex justify='space-between' p={2}>
                                                    <Text>Last score: {item.quizScore}%  </Text>
                                                    {item.showQuiz ? <Text color='red'>Failed</Text> : <Text color='green'>Pass</Text>}
                                                </Flex>
                                                : null}
                                            {!!licInfo ? <Text color='gray.400' alignSelf='center'>Min passing score {licInfo.ChapterWiseFormat.passingPercentage}%</Text> : ""}
                                        </Flex>


                                    </Box>
                                    : null}
                            </Flex>
                        )
                    })
                    }
                    <AlertDialog
                        motionPreset='slideInBottom'
                        isOpen={isAlertOpen}
                        onClose={() => showAlert(false)}
                        isCentered
                    >
                        <AlertDialogOverlay />

                        <AlertDialogContent>
                            <AlertDialogHeader>Locked</AlertDialogHeader>
                            <AlertDialogCloseButton onClick={() => showAlert(false)} />
                            <AlertDialogBody>
                                Module is locked, please read all the topics of previous module to unlock this.
                            </AlertDialogBody>

                        </AlertDialogContent>
                    </AlertDialog>

                </SimpleGrid> : <CircularProgress value={30} isIndeterminate color='orange.400' thickness='12px' />
            }
        </Flex >
    );
}
