// Chakra imports
import { ArrowBackIcon, ArrowForwardIcon, ChevronRightIcon, LockIcon } from "@chakra-ui/icons";
import {
    Box,
    IconButton,
    Flex,
    Button,
    Progress,
    Text, Input, Icon, Code,
    SimpleGrid, Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    List,
    ListItem,
    ListIcon,
    OrderedList,
    UnorderedList,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,




} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { BsCheckCircle, BsFillPatchCheckFill } from "react-icons/bs";
import { MdOutlineCancel, MdOutlineWbIncandescent, MdRunningWithErrors } from "react-icons/md";
import api from '../../helpers/Fetch';
import utils from '../../helpers/utils';
import { saveAs } from "file-saver";

let bartimer = null;
let answers = []

export default function Topics(props) {
    const [data, setData] = useState(null);
    const [licData, setLicData] = useState(null);
    const [isOpen, setOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const nameRef = React.useRef(null);
    let API = new api();
    let Util = new utils();
    let lang = localStorage.getItem('lang') || 'english';

    useEffect(() => {
        getLicenceInfo("english")
        getQuizResult({ attemptId: localStorage.getItem('latest_attempt_id') });
    }, [])
    const getLicenceInfo = (lang) => {
        API.getLicenceInfo({ lang: lang }).then((res) => {
            console.log(res.data);
            setLicData(res.data.data);

        }).then((err) => {
            console.log(err);
            Util.showServerError(err);
        })
    }
    const getQuizResult = (data) => {
        API.getQuizDetails(data).then((res) => {
            console.log(res.data);
            setData(res.data.data);

        }).then((err) => {
            console.log(err);
            Util.showServerError(err);
        })
    }
    const generateCertificate = () => {
        let name = nameRef.current.value;
        if (!name) {
            Util.showError('Please enter your full name');
            return;
        }
        setLoading(true)
        API.generateCertificate({ name: name }).then((res) => {
            console.log(res.data);
            setLicData(res.data.data);
            setOpen(false);
            setLoading(false)

        }).then((err) => {
            console.log(err);
            setLoading(false)
            Util.showServerError(err);
        })
    }
    const download = (url, name) => {
        saveAs(
            url,
            `${name}.pdf`
        )
    }


    return (
        <Flex flexDirection="column" pt={{ base: "0px", md: "0px" }} h='100vh'>
            <Flex justify='space-between' mb={6} p={4}>
                <Flex fontSize='md' fontWeight='semibold' align='center'>
                    <IconButton aria-label='full screen' variant='link' icon={<ArrowBackIcon />} onClick={() => props.history.push('/dashboard')} />
                    <Text>Result</Text>
                </Flex>
            </Flex>
            {!!data && !!licData ?
                <Box w='70%' alignSelf='center'>
                    <Flex direction='column'>
                        <SimpleGrid columns={{ sm: 1, md: 3 }} spacing='6'>
                            <Box boxShadow='md' borderRadius='10px' bg='green.100' p={3}>
                                <Flex direction='column'>
                                    <Text fontWeight='normal' color='black' fontSize='large'>Correct Attempts</Text>
                                    <Text fontWeight='bold' color='green.600' fontSize='large'>{data.correct}</Text>
                                </Flex>
                            </Box>
                            <Box boxShadow='md' borderRadius='10px' bg='red.100' p={3}>
                                <Flex direction='column'>
                                    <Text fontWeight='normal' color='black' fontSize='large'>Wrong Attempts</Text>
                                    <Text fontWeight='bold' color='red.400' fontSize='large'>{data.incorrect}</Text>
                                </Flex>
                            </Box>
                            <Box boxShadow='md' borderRadius='10px' bg='gray.100' p={3}>
                                <Flex direction='column'>
                                    <Text fontWeight='normal' color='black' fontSize='large'>Skipped</Text>
                                    <Text fontWeight='bold' color='gray.600' fontSize='large'>{data.skipped}</Text>
                                </Flex>
                            </Box>
                        </SimpleGrid>
                        <Box boxShadow='md' borderRadius='10px' bg='gray.100' p={3} mt='10'>
                            <Flex justify='space-between' align='center'>
                                <Flex direction='column'>
                                    <Flex align='center'>
                                        {data.isPass ?
                                            <Icon as={BsFillPatchCheckFill} color='green' w={'40px'} h={'40px'} />
                                            :
                                            <Icon as={MdRunningWithErrors} color='red.400' w={'30px'} h={'30px'} />
                                        }

                                        <Text color={data.isPass ? 'green' : 'red.400'} fontSize='x-large' ms='3'>{data.isPass ? 'Passed' : 'Failed'}</Text>
                                    </Flex>
                                    <Text>Score : {data.marksObtained}/100</Text>
                                </Flex>
                                {data.showButton ? <>
                                    {data.isPass ? (!licData.certificateUrl ?
                                        <Button variant='solid' colorScheme='green' onClick={() => setOpen(true)}>
                                            Get your certifcate
                                        </Button> :
                                        <Button variant='solid' colorScheme='green' onClick={() => download(licData.certificateUrl, licData.licence)}>
                                            Download Now
                                        </Button>) :
                                        <Button variant='solid' colorScheme='orange' onClick={() => props.history.push('/run/quiz')}>
                                            Retry
                                        </Button>}</>
                                    : null}
                            </Flex>
                        </Box>
                        <Box>
                            <Accordion mt='10' allowMultiple bg='gray.200'>
                                <AccordionItem >
                                    <h2>
                                        <AccordionButton>
                                            <Box flex='1' textAlign='left'>
                                                View Quiz Summary
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4} bg='gray.100'>
                                        <UnorderedList>
                                            {data.questions.map(item => {
                                                return (<ListItem>
                                                    <Box boxShadow='md' borderRadius='10px' bg={item.answer === 'correct' ? 'green.100' : item.answer === 'wrong' ? 'red.100' : "grey.100"} p={3} mt='10'>
                                                        <Flex justify='space-between'>
                                                            <Flex direction='column'>
                                                                <Text><b>Question:</b>  {item.question}</Text>
                                                                <Text><b>Option Choosen:</b>  {item.selectedOption}</Text>
                                                            </Flex>
                                                            <Text fontSize='sm' fontWeight='bold'>
                                                                {item.answer.toUpperCase()}
                                                            </Text>
                                                        </Flex>
                                                    </Box>
                                                </ListItem>);

                                            })}
                                        </UnorderedList>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        </Box>
                    </Flex>
                </Box > : null
            }
            <Modal isOpen={isOpen} onClose={() => setOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Fill Details (Fill Carefully, Cannot be changed after submission.)</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>Enter your name (Used in certificate)</Text>
                        <Input type='text' placeholder='full name' ref={nameRef} />
                    </ModalBody>

                    <ModalFooter>

                        <Button variant='solid' colorScheme='green' isLoading={isLoading} onClick={generateCertificate}>Submit</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

        </Flex >
    );
}
