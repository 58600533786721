// Chakra imports
import { DownloadIcon, LockIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Input,
  Progress,
  SimpleGrid,
  Spacer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tooltip,
  Text,
  Th,
  CircularProgress,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
  useColorModeValue,
  Divider,
} from "@chakra-ui/react";
// assets
import peopleImage from "assets/img/people-image.png";
import logoChakra from "assets/svg/logo-white.svg";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import BarChart from "components/Charts/BarChart";
import LineChart from "components/Charts/LineChart";
import IconBox from "components/Icons/IconBox";
// Custom icons
import {
  CartIcon,
  DocumentIcon,
  GlobeIcon,
  RocketIcon,
  StatsIcon,
  WalletIcon,
} from "components/Icons/Icons.js";
import DashboardTableRow from "components/Tables/DashboardTableRow";
import TimelineRow from "components/Tables/TimelineRow";
import React, { useState, useEffect } from "react";
import { AiOutlineRead } from "react-icons/ai";
import { BiBriefcase } from "react-icons/bi";
// react icons
import { BsArrowRight } from "react-icons/bs";
import { FaCertificate, FaClock, FaLanguage } from "react-icons/fa";
import { IoCheckmarkDoneCircleSharp, IoTimerOutline } from "react-icons/io5";
import {
  MdOndemandVideo,
  MdOutlineQuiz,
  MdOutlineWbIncandescent,
} from "react-icons/md";
import { RiTimerFlashLine } from "react-icons/ri";
import api from "../../helpers/Fetch";
import utils from "../../helpers/utils";
import { saveAs } from "file-saver";

export default function Dashboard(props) {
  const value = "$100.000";
  // Chakra Color Mode
  const iconTeal = useColorModeValue("red.200", "red.200");
  const iconGreen = useColorModeValue("green.200", "green.200");
  const iconBoxInside = useColorModeValue("white", "white");
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [showCertGenerator, setShowCertGenerator] = useState(false);
  const [showCertDisabled, setShowCertDisabled] = useState(false);
  const nameRef = React.useRef(null);

  let API = new api();
  let Util = new utils();
  useEffect(() => {
    getLicenceInfo("english");
  }, []);
  const getLicenceInfo = (lang) => {
    API.getLicenceInfo({ lang: lang })
      .then((res) => {
        console.log(res.data);
        setData(res.data.data);
      })
      .then((err) => {
        console.log(err);
        Util.showServerError(err);
      });
  };
  const showMsg = (lang) => {
    Util.showInfo("Default language changed to " + lang, "");
  };
  const startQuiz = () => {
    if (!!data) {
      if (data.quizIncluded === false) {
        alert("Quiz is not included in your plan.");
        return;
      }
      if (
        data.learningType == "FORCE" &&
        (data.attemptedReadTime / data.avarageReadTime) * 100 < 100
      ) {
        alert("Please complete all modules to unlock quiz");
        return;
      }
      props.history.push("/run/quiz");
    }
  };
  const showCertificate = (canGenerate) => {
    if (canGenerate) {
      setShowCertGenerator(true);
    } else {
      setShowCertDisabled(true);
    }
  };
  const generateCertificate = () => {
    let name = nameRef.current.value;
    if (!name) {
      Util.showError("Please enter your full name");
      return;
    }
    setLoading(true);
    API.generateCertificate({ name: name })
      .then((res) => {
        console.log(res.data);
        getLicenceInfo("english");
      })
      .then((err) => {
        console.log(err);
        Util.showServerError(err);
      });
  };
  const DownloadCerticate = (link) => {
    if (!!data) {
      saveAs(link, `${data.licence}.pdf`);
    }
  };

  return (
    <Flex flexDirection="column" pt={{ base: "20px", md: "15px" }}>
      <Flex justify="space-between">
        <Text fontSize="large" fontWeight="semibold" m="3">
          Features
        </Text>
        {!!data && data.certificateUrl === null ? (
          data.showCertificateButton ? (
            <Button
              borderRadius="2px"
              p="10px"
              variant="solid"
              bg={
                !!data && data.showCertificateButton ? "green.400" : "gray.400"
              }
              mt="12px"
              _hover={{ me: "-4px" }}
              transition="all .5s ease"
              onClick={() => showCertificate(data.showCertificateButton)}
            >
              <Flex align="center" textAlign="center">
                <Icon
                  as={FaCertificate}
                  w="16px"
                  color="white"
                  h="16px"
                  fontSize="xl"
                  transition="all .5s ease"
                  mx=".3rem"
                  cursor="pointer"
                  _hover={{ transform: "translateX(20%)" }}
                />
                <Text fontSize="sm" fontWeight="bold" color="white">
                  Get Certificate
                </Text>
              </Flex>
            </Button>
          ) : null
        ) : (
          <Button
            borderRadius="2px"
            p="10px"
            variant="solid"
            bg={"green.400"}
            mt="12px"
            _hover={{ me: "-4px" }}
            transition="all .5s ease"
            onClick={() => DownloadCerticate(data.certificateUrl)}
          >
            <Flex align="center" textAlign="center">
              <Icon
                as={FaCertificate}
                w="16px"
                color="white"
                h="16px"
                fontSize="xl"
                transition="all .5s ease"
                mx=".3rem"
                cursor="pointer"
                _hover={{ transform: "translateX(20%)" }}
              />
              <Text fontSize="sm" fontWeight="bold" color="white">
                Download Certificate
              </Text>
            </Flex>
          </Button>
        )}
      </Flex>
      {!!data ? (
        <SimpleGrid columns={{ sm: 1, md: 2, xl: 3 }} spacing="24px">
          <Card maxHeight="290.5px" p="1rem" bgColor="white">
            <CardBody
              p="0px"
              h={{ sm: "200px", lg: "100%" }}
              borderRadius="1px"
            >
              <Flex
                flexDirection="column"
                color="black"
                lineHeight="1.6"
                w="100%"
                pt="2"
                ps="2"
              >
                <Text fontSize="xl" fontWeight="bold" pb=".3rem">
                  Read Content
                </Text>
                <Text
                  fontSize="sm"
                  fontWeight="normal"
                  w={{ lg: "100%" }}
                  fontWeight="bold"
                  color="gray.600"
                >
                  Read all the training modules to complete the training.
                </Text>
                <Flex mt="4" w="100%" justify="space-between">
                  <Flex
                    direction="column"
                    justifyContent="center"
                    align="center"
                  >
                    <IconBox as="box" h={"45px"} w={"45px"} bg={iconTeal}>
                      <Icon
                        as={IoTimerOutline}
                        h={"24px"}
                        w={"24px"}
                        color={iconBoxInside}
                      />
                    </IconBox>
                    <Text
                      fontSize="xs"
                      pt="1"
                      fontWeight="bold"
                      color="gray.600"
                    >
                      ~{" "}
                      {data.avarageReadTime > 60
                        ? `${(data.avarageReadTime / 60).toFixed(1)} mins `
                        : `${data.avarageReadTime} secs`}
                    </Text>
                  </Flex>
                  <Flex
                    direction="column"
                    justifyContent="center"
                    align="center"
                  >
                    <IconBox as="box" h={"45px"} w={"45px"} bg={iconTeal}>
                      <Icon
                        as={AiOutlineRead}
                        h={"24px"}
                        w={"24px"}
                        color={iconBoxInside}
                      />
                    </IconBox>
                    <Text
                      fontSize="xs"
                      pt="1"
                      fontWeight="bold"
                      color="gray.600"
                    >
                      {data.chapterCount}+ Topics
                    </Text>
                    {data.contentIncluded === false ? (
                      <Icon
                        as={LockIcon}
                        position="relative"
                        bottom="74px"
                        left="18px"
                        color="gray.600"
                        boxShadow="lg"
                      />
                    ) : null}
                  </Flex>
                  <Flex
                    direction="column"
                    justifyContent="center"
                    align="center"
                  >
                    <IconBox as="box" h={"45px"} w={"45px"} bg={iconTeal}>
                      <Icon
                        as={MdOndemandVideo}
                        h={"24px"}
                        w={"24px"}
                        color={iconBoxInside}
                      />
                    </IconBox>
                    <Text
                      fontSize="xs"
                      pt="1"
                      fontWeight="bold"
                      color="gray.600"
                    >
                      Videos
                    </Text>
                    {data.animatedVideos === false ? (
                      <Icon
                        as={LockIcon}
                        position="relative"
                        bottom="74px"
                        left="18px"
                        color="gray.600"
                        boxShadow="lg"
                      />
                    ) : null}
                  </Flex>
                  <Flex
                    direction="column"
                    justifyContent="center"
                    align="center"
                  >
                    <IconBox as="box" h={"45px"} w={"45px"} bg={iconTeal}>
                      <Icon
                        as={FaLanguage}
                        h={"24px"}
                        w={"24px"}
                        color={iconBoxInside}
                      />
                    </IconBox>
                    <Text
                      fontSize="xs"
                      pt="1"
                      fontWeight="bold"
                      color="gray.600"
                    >
                      Multilingual
                    </Text>
                    {data.languageSupport.length == 1 ? (
                      <Icon
                        as={LockIcon}
                        position="relative"
                        bottom="74px"
                        left="18px"
                        color="gray.600"
                        boxShadow="lg"
                      />
                    ) : null}
                  </Flex>
                </Flex>
                <Text fontSize="sm" color="gray.700" fontWeight="semibold">
                  Read completion
                </Text>
                <Box borderRadius="10px" overflow="hidden">
                  <Progress
                    colorScheme="red"
                    size="md"
                    value={
                      (data.attemptedReadTime / data.avarageReadTime) * 100
                    }
                  />
                </Box>
                <Spacer />
                <Flex align="center" justify="flex-end" w="100%">
                  <Button
                    p="10px"
                    variant="solid"
                    bg="red.100"
                    mt="12px"
                    _hover={{ me: "-4px" }}
                    transition="all .5s ease"
                    onClick={() => props.history.push("/c/view")}
                  >
                    <Flex align="center" textAlign="center">
                      {data.contentIncluded === false ? (
                        <Icon
                          as={LockIcon}
                          w="16px"
                          h="16px"
                          fontSize="xl"
                          transition="all .5s ease"
                          mx=".3rem"
                          cursor="pointer"
                          _hover={{ transform: "translateX(20%)" }}
                        />
                      ) : null}
                      <Text fontSize="sm" fontWeight="bold">
                        Start Reading
                      </Text>
                    </Flex>
                  </Button>
                </Flex>
              </Flex>
            </CardBody>
          </Card>
          {data.quizType === "NORMAL" ? (
            <Card
              maxHeight="290.5px"
              p="1rem"
              bgColor="white"
              _hover={{ me: "-14px" }}
              transition="all .5s ease"
            >
              <CardBody
                p="0px"
                h={{ sm: "200px", lg: "100%" }}
                borderRadius="1px"
              >
                <Flex
                  flexDirection="column"
                  color="black"
                  lineHeight="1.6"
                  w="100%"
                  pt="2"
                  ps="2"
                >
                  <Text fontSize="xl" fontWeight="bold" pb=".3rem">
                    Get Certificate
                  </Text>
                  <Text
                    fontSize="sm"
                    fontWeight="normal"
                    w={{ lg: "100%" }}
                    fontWeight="bold"
                    color="gray.600"
                  >
                    Pass the quiz, and get your training certification.
                  </Text>
                  <Flex mt="4" w="100%" justify="space-between" align="center">
                    <Flex
                      direction="column"
                      justifyContent="center"
                      align="center"
                    >
                      <IconBox as="box" h={"45px"} w={"45px"} bg={iconGreen}>
                        <Icon
                          as={MdOutlineQuiz}
                          h={"24px"}
                          w={"24px"}
                          color={iconBoxInside}
                        />
                      </IconBox>
                      <Text
                        fontSize="xs"
                        pt="1"
                        fontWeight="bold"
                        color="gray.600"
                      >
                        {data.quizCount}+ Questions
                      </Text>
                    </Flex>
                    <Flex
                      direction="column"
                      justifyContent="center"
                      align="center"
                    >
                      <IconBox as="box" h={"45px"} w={"45px"} bg={iconGreen}>
                        <Icon
                          as={RiTimerFlashLine}
                          h={"24px"}
                          w={"24px"}
                          color={iconBoxInside}
                        />
                      </IconBox>
                      <Text
                        fontSize="xs"
                        pt="1"
                        fontWeight="bold"
                        color="gray.600"
                      >
                        Timer
                      </Text>
                    </Flex>
                    <Flex
                      direction="column"
                      justifyContent="center"
                      align="center"
                    >
                      <IconBox as="box" h={"45px"} w={"45px"} bg={iconGreen}>
                        <Icon
                          as={MdOutlineWbIncandescent}
                          h={"24px"}
                          w={"24px"}
                          color={iconBoxInside}
                        />
                      </IconBox>
                      <Text
                        fontSize="xs"
                        pt="1"
                        fontWeight="bold"
                        color="gray.600"
                      >
                        Explanations
                      </Text>
                      {!data.quizExplanation ? (
                        <Icon
                          as={LockIcon}
                          position="relative"
                          bottom="74px"
                          left="18px"
                          color="gray.600"
                          boxShadow="lg"
                        />
                      ) : null}
                    </Flex>
                    <Flex
                      direction="column"
                      justifyContent="center"
                      align="center"
                    >
                      <IconBox as="box" h={"45px"} w={"45px"} bg={iconGreen}>
                        <Icon
                          as={FaLanguage}
                          h={"24px"}
                          w={"24px"}
                          color={iconBoxInside}
                        />
                      </IconBox>
                      <Text
                        fontSize="xs"
                        pt="1"
                        fontWeight="bold"
                        color="gray.600"
                      >
                        Multilingual
                      </Text>
                      {data.languageSupport.length == 1 ? (
                        <Icon
                          as={LockIcon}
                          position="relative"
                          bottom="74px"
                          left="18px"
                          color="gray.600"
                          boxShadow="lg"
                        />
                      ) : null}
                    </Flex>
                  </Flex>
                  <Text
                    fontSize="sm"
                    color="gray.700"
                    pt="4px"
                    fontWeight="semibold"
                  >
                    Total Attempts
                  </Text>
                  <Text fontSize="sm">{data.quizAttempts.fails}</Text>
                  <Spacer />
                  <Flex align="center" justify="flex-end" w="100%">
                    {data.certificateUrl === null ? (
                      data.learningType === "FORCE" &&
                      (data.attemptedReadTime / data.avarageReadTime) * 100 <
                        100 ? (
                        <Tooltip label="Read all content to unlock" hasArrow>
                          <Button
                            p="10px"
                            variant="solid"
                            bg="green.200"
                            mt="8px"
                            _hover={{ me: "-4px" }}
                            transition="all .5s ease"
                          >
                            <Flex align="center" textAlign="center">
                              <Icon
                                as={LockIcon}
                                w="16px"
                                h="16px"
                                fontSize="xl"
                                transition="all .5s ease"
                                mx=".3rem"
                                cursor="pointer"
                                _hover={{ transform: "translateX(20%)" }}
                              />
                              <Text fontSize="sm" fontWeight="bold">
                                Locked
                              </Text>
                            </Flex>
                          </Button>
                        </Tooltip>
                      ) : (
                        <Button
                          p="10px"
                          variant="solid"
                          bg="green.200"
                          mt="8px"
                          _hover={{ me: "-4px" }}
                          onClick={startQuiz}
                          transition="all .5s ease"
                        >
                          <Flex align="center" textAlign="center">
                            {data.quizIncluded === false ||
                            (data.learningType === "FORCE" &&
                              (data.attemptedReadTime / data.avarageReadTime) *
                                100 <
                                100) ? (
                              <Icon
                                as={LockIcon}
                                w="16px"
                                h="16px"
                                fontSize="xl"
                                transition="all .5s ease"
                                mx=".3rem"
                                cursor="pointer"
                                _hover={{ transform: "translateX(20%)" }}
                              />
                            ) : null}
                            <Text fontSize="sm" fontWeight="bold">
                              Start Quiz
                            </Text>
                          </Flex>
                        </Button>
                      )
                    ) : (
                      <Button
                        p="10px"
                        variant="solid"
                        bg="green.200"
                        mt="12px"
                        _hover={{ me: "-4px" }}
                        transition="all .5s ease"
                        onClick={() => DownloadCerticate(data.certificateUrl)}
                      >
                        <Flex align="center" textAlign="center">
                          <Icon
                            as={DownloadIcon}
                            w="16px"
                            h="16px"
                            fontSize="xl"
                            transition="all .5s ease"
                            mx=".3rem"
                            cursor="pointer"
                            _hover={{ transform: "translateX(20%)" }}
                          />
                          <Text fontSize="sm" fontWeight="bold">
                            Download Certificate
                          </Text>
                        </Flex>
                      </Button>
                    )}
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
          ) : null}
        </SimpleGrid>
      ) : (
        <CircularProgress
          value={30}
          isIndeterminate
          color="orange.400"
          thickness="12px"
        />
      )}
      <Modal
        isOpen={showCertGenerator}
        onClose={() => setShowCertGenerator(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Fill Details (Fill Carefully, Cannot be changed after submission.)
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Enter your name (Used in certificate)</Text>
            <Input type="text" placeholder="full name" ref={nameRef} />
          </ModalBody>

          <ModalFooter>
            <Button
              variant="solid"
              colorScheme="green"
              isLoading={isLoading}
              onClick={generateCertificate}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={showCertDisabled}
        onClose={() => setShowCertDisabled(false)}
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Certificate instructions</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb="6">
              To get your certificate follow the instructions below:
            </Text>
            <UnorderedList>
              <ListItem>Read all the content.</ListItem>
              <ListItem>
                After reading all topics in a chapter, you can take quiz for
                that chapter. Clear quiz of every chapter included
              </ListItem>
              <ListItem>
                Depending on your licence plan, either you need to clear quiz
                individually for every chapter or collectively score more than
                or equals to passing score.
              </ListItem>
            </UnorderedList>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="solid"
              colorScheme="green"
              isLoading={isLoading}
              onClick={() => setShowCertDisabled(false)}
            >
              Got it
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
