// Chakra imports
import { ArrowBackIcon, ArrowForwardIcon, ChevronRightIcon, LockIcon } from "@chakra-ui/icons";
import {
    Box,
    IconButton,
    Flex,
    Button,
    Progress,
    Text, Stack, Icon, Code,
    SimpleGrid, Radio, RadioGroup,
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogOverlay, CircularProgress,


} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { BsCheckCircle } from "react-icons/bs";
import { MdOutlineCancel, MdOutlineWbIncandescent } from "react-icons/md";
import api from '../../helpers/Fetch';
import utils from '../../helpers/utils';

let bartimer = null;
let answers = []

export default function Topics(props) {
    const [licInfo, setLicInfo] = useState(null);
    const [timer, setTimerTime] = useState(null);
    const [data, setData] = useState(null);
    const [getIndex, setIndex] = useState(0);
    const [question, setQuestion] = useState(null);
    const [value, setValue] = React.useState('')
    const [showExplain, setShowExplaination] = useState(false);
    const [progressBars, setProgressBars] = useState(null);
    const [showSaving, setSaving] = useState(false);

    let API = new api();
    let Util = new utils();
    let lang = localStorage.getItem('lang') || 'english';

    useEffect(() => {
        getLicenceInfo(lang);
    }, [])
    const getLicenceInfo = (lang) => {
        API.getLicenceInfo({ lang: lang }).then((res) => {
            console.log(res.data);
            setLicInfo(res.data.data);
            getQuiz(res.data.data);

        }).then((err) => {
            console.log(err);
            Util.showServerError(err);
        })
    }
    const getQuiz = (licInfo) => {
        API.getQuiz({ lang: lang, moduleId: localStorage.getItem('quiz_chapter_id') }).then((res) => {
            console.log(res.data);
            setData(res.data.data);
            let pgBars = [];
            res.data.data.map((item, i) => {
                pgBars.push({
                    barId: `${i + 1}`,
                    progress: 0
                })
            })
            setProgressBars(pgBars);
            setQuestion(res.data.data[0]);
            setIndex(0);
            startTimer(licInfo, res.data.data, pgBars, 0);

        }).then((err) => {
            console.log(err);
            Util.showServerError(err);
        })
    }
    const startTimer = (licInfo, data, progressBars, i) => {
        if (bartimer == null) {
            if (!!licInfo && licInfo.quizTimer === true && !!data && !!progressBars) {
                let time = licInfo.quizTimerDurationInSec;
                bartimer = setInterval(() => {
                    let bars = progressBars;
                    bars.map(e => {
                        if (e.barId === `${i + 1}`) {
                            // console.log(e.progress)
                            if (e.progress >= 100) {
                                switchQuestion(licInfo, data, progressBars, i)
                            }
                            e.progress += (10 / time);
                        }
                    })
                    setProgressBars([...bars]);
                }, 1000 / time)
            }
            else {
                console.log(`data not geted ${licInfo} ${data}`)
            }
        }
    }
    const switchQuestion = (licInfo, d, pbars, i) => {
        if (pbars === undefined) {
            pbars = progressBars;
        }
        if (i === undefined) {
            i = getIndex;
        }
        if (d === undefined) {
            d = data;
        }
        console.log('current ans', value);
        answers.push({ questionId: question.questionId, response: value });
        pbars[getIndex].progress = 100;
        clearInterval(bartimer);
        if (!!d && getIndex >= d.length - 1) {
            console.log('quiz completed', JSON.stringify(answers));
            setSaving(true);
            API.saveQuiz({ data: answers, moduleId: localStorage.getItem('quiz_chapter_id') }).then((res) => {
                console.log(res.data);
                setSaving(false);
                answers = [];
                localStorage.setItem('latest_attempt_id', res.data.data.attemptId);
                props.history.push('/quiz/result');

            }).then((err) => {
                console.log(err);
                Util.showServerError(err);
            })
            return;
        }
        setValue('');
        setShowExplaination(false);
        clearInterval(bartimer);
        pbars[i].progress = 100;
        bartimer = null;
        setIndex(i + 1)
    }
    const stopTimer = (bartimer) => {
        if (bartimer !== null) {
            progressBars[getIndex].progress = 100;
            clearInterval(bartimer)
        }
    }
    useEffect(() => {
        if (!!data) {
            setQuestion(data[getIndex]);
            startTimer(licInfo, data, progressBars, getIndex)
        }
    }, [getIndex])


    return (
        <Flex flexDirection="column" pt={{ base: "0px", md: "0px" }} bg='white' h='100vh'>
            <Flex justify='space-between' mb={6} p={4}>
                <Flex fontSize='md' fontWeight='semibold' align='center'>
                    <IconButton aria-label='full screen' variant='link' icon={<ArrowBackIcon />} onClick={() => props.history.goBack()} />
                    <Text>Quiz</Text>
                </Flex>
                <Flex align='center'>
                    <Button fontSize='sm' fontWeight='bold' color={!!timer ? timer.color : 'black'}>Skip</Button>
                </Flex>
            </Flex>
            {!!data && !!question && !!progressBars ?
                <>
                    <SimpleGrid columns={progressBars.length} me={4} ms={4}>
                        {progressBars.map(item => {
                            return (
                                <Box borderRadius={10} me='2' overflow='hidden'>
                                    <Progress colorScheme='green' size='sm' value={item.progress} />
                                </Box>
                            );
                        })}
                    </SimpleGrid>


                    <Flex direction='column' w='100%' textAlign='center'>
                        <Text fontSize='sm' color='red.300' fontWeight='semibold' mt={"40px"}>Question {getIndex + 1}/{data.length}</Text>
                        <Text fontSize={{ sm: 'md', md: 'x-large' }} color='black' fontWeight='extrabold' mt={"8px"}>{question.question}</Text>
                        <RadioGroup alignSelf='center' maxW='50%' minW='30%' onChange={(e) => { stopTimer(bartimer); setValue(e) }} value={value} mt={10}>
                            <Stack direction='column' >
                                {question.options.map((option, i) => {
                                    return (
                                        <Flex direction='row' bg='gray.50' color='black' textAlign='start' borderRadius='20px' justify='space-between'>
                                            <Radio value={`${i + 1}`} p={3} px={5} isDisabled={value !== ''}>
                                                <Text>
                                                    {option.option}
                                                </Text>
                                            </Radio>
                                            {value !== '' && (i + 1) === parseInt(value) ?
                                                < Icon as={option.isCorrect === true ? BsCheckCircle : MdOutlineCancel} color={option.isCorrect === true ? 'green.400' : 'red.400'} w={"30px"} h={'30px'} alignSelf='center' me='3' />
                                                : null}
                                        </Flex>
                                    );

                                })}
                            </Stack>
                        </RadioGroup>
                        {value !== '' ?
                            <Box alignSelf='center' maxW='50%' minW='30%' mt='10'>
                                <Flex justify='space-between'>
                                    {!!licInfo && licInfo.quizExplanation === true ?
                                        <Button size="sm" p="10px" variant="solid" _hover={{ ms: "-4px" }} onClick={() => setShowExplaination(!showExplain)}
                                            transition="all .5s ease">
                                            <Flex align='center' textAlign='center'>
                                                <Icon
                                                    as={MdOutlineWbIncandescent}
                                                    w="16px"
                                                    h="16px"
                                                    fontSize="xl"
                                                    transition="all .5s ease"
                                                    mx=".3rem"
                                                    cursor="pointer"
                                                    _hover={{ transform: "translateX(20%)" }}
                                                />
                                                <Text
                                                    fontSize="sm"
                                                    fontWeight="light"
                                                >
                                                    Show Explanation
                                                </Text>

                                            </Flex>
                                        </Button> : null}
                                    <Button size='sm' p="10px" variant="solid" bg="green.200" _hover={{ me: "-4px" }}
                                        transition="all .5s ease" onClick={() => switchQuestion()}>
                                        <Flex align='center' textAlign='center'>

                                            <Text
                                                fontSize="sm"
                                                fontWeight="light"
                                            >
                                                Next Question
                                            </Text>
                                            <Icon
                                                as={ArrowForwardIcon}
                                                w="16px"
                                                h="16px"
                                                fontSize="xl"
                                                transition="all .5s ease"
                                                mx=".3rem"
                                                cursor="pointer"
                                                _hover={{ transform: "translateX(20%)" }}
                                            />
                                        </Flex>
                                    </Button>
                                </Flex>
                            </Box> : null}
                        {showExplain ?
                            <Box w='50%' textAlign='start' alignSelf='center' bg='gray.100' mt='10' borderRadius='10px' p={3}>
                                <Text fontWeight='bold'>Explanation</Text>
                                <Code children={question.explanation} />
                            </Box> : null}


                    </Flex>
                </>
                : null}
            <AlertDialog
                motionPreset='slideInBottom'
                onClose={() => setSaving(false)}
                isOpen={showSaving}
                isCentered
            >
                <AlertDialogOverlay />

                <AlertDialogContent>
                    <AlertDialogBody>
                        <Flex align='center'>
                            <CircularProgress isIndeterminate color='green.300' />
                            <Text fontSize='md' fontWeight='bold' ms='3'>Please wait...fetching result</Text>
                        </Flex>
                    </AlertDialogBody>

                </AlertDialogContent>
            </AlertDialog>
        </Flex >
    );
}
