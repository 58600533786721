// Chakra imports
import { ArrowBackIcon, LockIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  IconButton,
  Progress,
  SimpleGrid,
  Spacer,
  Select,
  StatHelpText,
  StatLabel,
  StatNumber,
  Table,
  Tooltip,
  LinkBox,
  LinkOverlay,
  Text,
  Th,
  CircularProgress,
  Thead,
  Tr,
  AlertDialog,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import React, { useState, useEffect } from "react";
import { BiBriefcase, BiTimeFive } from "react-icons/bi";
import { IoVideocamOutline } from "react-icons/io5";
import api from "../../helpers/Fetch";
import utils from "../../helpers/utils";
import ReactHtmlParser from "react-html-parser";

export default function Topics(props) {
  const [licInfo, setLicInfo] = useState(null);
  const [isAlertOpen, showAlert] = useState(false);
  const [data, setData] = useState(null);
  let API = new api();
  let Util = new utils();
  let previousdata = JSON.parse(localStorage.getItem("cdata"));

  useEffect(() => {
    getLicenceInfo();
  }, []);
  const getLicenceInfo = (lang) => {
    API.getLicenceInfo({ lang: lang })
      .then((res) => {
        console.log(res.data);
        setLicInfo(res.data.data);
        getTopics();
      })
      .then((err) => {
        console.log(err);
        Util.showServerError(err);
      });
  };
  const getTopics = () => {
    API.getTopics({ lang: previousdata.lang, moduleId: previousdata.moduleId })
      .then((res) => {
        console.log(res.data);
        setData(res.data.data);
      })
      .then((err) => {
        console.log(err);
        Util.showServerError(err);
      });
  };
  const openContent = (id, name, isLast, ids) => {
    localStorage.setItem(
      "tdata",
      JSON.stringify({
        topicId: id,
        lang: previousdata.lang,
        topicIds: ids,
        topicName: name,
        isLast: isLast,
        moduleId: previousdata.moduleId,
        chapterName: previousdata.name,
      })
    );
    props.history.push("/view/content");
  };

  return (
    <Flex flexDirection="column" pt={{ base: "20px", md: "15px" }}>
      <Flex justify="space-between" mb={6}>
        <Flex fontSize="x-large" fontWeight="semibold" m="3" align="center">
          <IconButton
            aria-label="full screen"
            variant="link"
            icon={<ArrowBackIcon />}
            onClick={() => props.history.goBack()}
          />
          <Text fontSize="md" ms="3">
            {previousdata.name}
          </Text>
        </Flex>
        <Select
          w="150px"
          variant="filled"
          bg="white"
          color="gray.600"
          size="sm"
          boxShadow="md"
        >
          {!!licInfo &&
            licInfo.languageSupport.map((item) => {
              return (
                <>
                  <option value={item.toLowerCase()}>
                    {item.toUpperCase()}
                  </option>
                </>
              );
            })}
        </Select>
      </Flex>
      {!!data ? (
        <SimpleGrid columns={{ sm: 1, md: 4, xl: 4 }} spacing="24px">
          {!!data &&
            data.map((item, i) => {
              return (
                <LinkBox rounded="sm">
                  <LinkOverlay
                    href=""
                    onClick={(e) => {
                      if (item.isLocked === false) {
                        let ids = [];
                        data.map((el, i) => {
                          ids.push({
                            index: i,
                            topicId: el.topicId,
                            name: el.name,
                          });
                        });
                        openContent(
                          item.topicId,
                          item.title,
                          i + 1 === data.length,
                          ids
                        );
                      } else {
                        e.preventDefault();
                        showAlert(true);
                      }
                    }}
                  >
                    <Tooltip
                      label={item.isLocked ? "Locked" : ""}
                      hasArrow
                      placement="top"
                    >
                      <Box _hover={{ mt: "-8px" }} transition="all .2s ease">
                        <Card
                          maxHeight="290.5px"
                          p="1rem"
                          bgColor={item.isLocked ? "red.100" : "green.100"}
                        >
                          <CardBody
                            p="0px"
                            h={{ sm: "200px", lg: "100%" }}
                            borderRadius="1px"
                          >
                            <Flex
                              flexDirection="column"
                              color="black"
                              lineHeight="1.6"
                              w="100%"
                              ps="2"
                            >
                              <Text
                                fontSize="md"
                                fontWeight="bold"
                                noOfLines={2}
                              >
                                {item.title}
                              </Text>
                              <Text
                                fontSize="xs"
                                fontWeight="normal"
                                noOfLines={3}
                              >
                                {item.sortText.includes("<iframe") ? (
                                  <Text fontSize="large" color="gray.600">
                                    Watch Video
                                  </Text>
                                ) : (
                                  <div>{ReactHtmlParser(item.sortText)}</div>
                                )}
                              </Text>
                              <Flex align="center" mt="3">
                                <Icon
                                  as={BiTimeFive}
                                  h={"20px"}
                                  w={"20px"}
                                  color="black"
                                />
                                <Text fontSize="sm" ms="1">
                                  {(item.attemptedReadTime / 60).toFixed(1)}{" "}
                                  mins / {(item.readTime / 60).toFixed(1)} mins
                                </Text>
                              </Flex>

                              <Box borderRadius="10px" overflow="hidden" mt="5">
                                <Progress
                                  colorScheme="green"
                                  size="sm"
                                  value={
                                    (item.attemptedReadTime / item.readTime) *
                                    100
                                  }
                                />
                              </Box>
                              <Spacer />
                              <Flex
                                align="center"
                                justify="flex-end"
                                w="100%"
                              ></Flex>
                            </Flex>
                          </CardBody>
                        </Card>
                      </Box>
                    </Tooltip>
                  </LinkOverlay>
                </LinkBox>
              );
            })}
          <AlertDialog
            motionPreset="slideInBottom"
            isOpen={isAlertOpen}
            onClose={() => showAlert(false)}
            isCentered
          >
            <AlertDialogOverlay />

            <AlertDialogContent>
              <AlertDialogHeader>Locked</AlertDialogHeader>
              <AlertDialogCloseButton onClick={() => showAlert(false)} />
              <AlertDialogBody>
                Topic is locked, please read previous topic to unlock this.
              </AlertDialogBody>
            </AlertDialogContent>
          </AlertDialog>
        </SimpleGrid>
      ) : (
        <CircularProgress
          value={30}
          isIndeterminate
          color="orange.400"
          thickness="12px"
        />
      )}
    </Flex>
  );
}
