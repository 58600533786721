// Chakra imports
import { ArrowBackIcon, ChevronRightIcon, LockIcon } from "@chakra-ui/icons";
import {
  Box,
  IconButton,
  Flex,
  Grid,
  Icon,
  Button,
  Tooltip,
  LinkBox,
  LinkOverlay,
  Text,
  Th,
  CircularProgress,
  Breadcrumb,
  BreadcrumbItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import React, { useState, useEffect } from "react";
import { BiBriefcase, BiTimeFive } from "react-icons/bi";
import { FiChevronRight } from "react-icons/fi";
import api from "../../helpers/Fetch";
import utils from "../../helpers/utils";
import ReactHtmlParser from "react-html-parser";
import { BsArrowsFullscreen } from "react-icons/bs";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

export default function Topics(props) {
  const [licInfo, setLicInfo] = useState(null);
  const [timer, setTimerTime] = useState(null);
  const [data, setData] = useState(null);
  const [showQuizStart, setShowQuizStart] = useState(false);
  const handle = useFullScreenHandle();
  let API = new api();
  let Util = new utils();
  let previousdata = JSON.parse(localStorage.getItem("tdata"));

  useEffect(() => {
    getLicenceInfo(previousdata.lang);
    getContent(previousdata.topicId);
    let handle = setInterval(() => {
      updateTime(previousdata.topicId);
    }, 5000);

    return () => {
      clearInterval(handle);
    };
  }, []);
  const getLicenceInfo = (lang) => {
    API.getLicenceInfo({ lang: lang })
      .then((res) => {
        console.log(res.data);
        setLicInfo(res.data.data);
      })
      .then((err) => {
        console.log(err);
        Util.showServerError(err);
      });
  };
  const getContent = (topicId) => {
    API.getContent({ lang: previousdata.lang, topicId: topicId })
      .then((res) => {
        console.log(res.data);
        if (res.data.data.remainingTime < 1) {
          setTimerTime({ time: "Completed", color: "green" });
          if (
            !!licInfo &&
            licInfo.quizType === "CHAPTER_WISE" &&
            previousdata.isLast
          ) {
            setShowStartQuiz(true);
          }
        } else {
          let time = res.data.data.remainingTime;
          setInterval(() => {
            if (time < 1) {
              if (
                !!licInfo &&
                licInfo.quizType === "CHAPTER_WISE" &&
                previousdata.isLast
              ) {
                setShowStartQuiz(true);
              }
              setTimerTime({ time: "Completed", color: "green" });
            } else {
              secondsToHms(time--);
            }
          }, 1000);
        }
        setData(res.data.data);
      })
      .then((err) => {
        console.log(err);
        Util.showServerError(err);
      });
  };
  const updateTime = (topicId) => {
    API.updateReadTime({ topicId: topicId })
      .then((res) => {
        console.log(res.data);
      })
      .then((err) => {
        console.log(err);
        Util.showServerError(err);
      });
  };
  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hrs, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " min, " : " mins, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " secs") : "";
    return setTimerTime({
      time: `${hDisplay} ${mDisplay} ${sDisplay}`,
      color: "red",
    });
  };

  return (
    <Flex flexDirection="column" pt={{ base: "20px", md: "15px" }}>
      <Flex justify="space-between" mb={6}>
        <Flex fontSize="md" fontWeight="semibold" align="center">
          <IconButton
            aria-label="full screen"
            variant="link"
            icon={<ArrowBackIcon />}
            onClick={() => props.history.goBack()}
          />
          <Breadcrumb
            spacing="8px"
            separator={<FiChevronRight color="gray.500" />}
          >
            <BreadcrumbItem>
              <Text fontSize="sm">{previousdata.chapterName}</Text>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <Text fontSize="sm">{previousdata.topicName}</Text>
            </BreadcrumbItem>
          </Breadcrumb>
        </Flex>
        <Flex align="center">
          {!!timer && timer.time == "Completed" ? (
            <Button
              onClick={() => {
                console.log("click");
                let data = JSON.parse(localStorage.getItem("tdata"));
                let ci = 0;
                data.topicIds.map((el, i) => {
                  if (el.topicId == previousdata.topicId) {
                    ci = i;
                  }
                });
                if (ci + 1 < data.topicIds.length) {
                  data.topicId = data.topicIds[ci + 1].topicId;
                  data.topicName = data.topicIds[ci + 1].name;
                } else {
                  return;
                }
                localStorage.setItem("tdata", JSON.stringify(data));
                window.location.reload();
              }}
            >
              Next
            </Button>
          ) : (
            <Text
              fontSize="sm"
              fontWeight="bold"
              me="10"
              color={!!timer ? timer.color : "black"}
            >
              Time Remaining : {!!timer ? timer.time : "-"}
            </Text>
          )}
          <Tooltip label="Fullscreen" hasArrow placement="auto">
            <IconButton
              aria-label="full screen"
              variant="link"
              icon={<BsArrowsFullscreen />}
              onClick={handle.enter}
            />
          </Tooltip>
        </Flex>
      </Flex>
      {!!data ? (
        <FullScreen handle={handle}>
          <Box bgColor="white" p={6} borderRadius="10px" boxShadow="sm">
            <Text fontSize="xs" fontWeight="normal">
              <div>
                {ReactHtmlParser(
                  data.content.includes("player.vimeo.com")
                    ? data.content.replace(
                        /(width=")\d+("\W+height=")\d+/,
                        "$1850$2700"
                      )
                    : data.content
                )}
              </div>
            </Text>
          </Box>
        </FullScreen>
      ) : (
        <CircularProgress
          value={30}
          isIndeterminate
          color="orange.400"
          thickness="12px"
        />
      )}

      <Modal
        blockScrollOnMount={true}
        closeOnOverlayClick={false}
        isCentered
        isOpen={showQuizStart}
        onClose={() => setShowQuizStart(false)}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Module completed</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb="6">
              Hey, you have completed all the topics in this chapter, now it's
              time to check what you learned
            </Text>
            <Text color="black" fontSize="large">
              Play a simple quiz!
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="solid"
              colorScheme="green"
              onClick={() => {
                localStorage.setItem("quiz_chapter_id", previousdata.moduleId);
                props.history.push("/run/quiz");
              }}
            >
              Got it
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
