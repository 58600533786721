
import { BrowserRouter, Switch } from "react-router-dom";


import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import auth from "./helpers/auth";
export default function App() {

    return (
        <>
            <BrowserRouter>
                <Switch>
                    {auth.isLoggedIn() ? (auth.getLoginRole() === 'E' ? <AdminLayout /> : <AuthLayout />) : <AuthLayout />}
                </Switch>
            </BrowserRouter>
        </>
    );
}
