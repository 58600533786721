import * as axios from "axios";

export default class Api {
    constructor() {
        this.api_token = null;
        this.client = null;
        // this.api_url = "http://localhost:3000/dev/api/v1";
        this.api_url = "https://api.centre4posh.com/api/v1";

    }

    init = () => {
        this.api_token = localStorage.getItem("ACCESS_TOKEN");

        let headers = {
            Accept: "*/*",
        };

        if (this.api_token) {
            headers.Authorization = `Bearer ${this.api_token}`;
        }

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers,
        });

        return this.client;
    };
    checkAuthToken = (props) => {
        return this.init().get("/auth/checkToken").then((res) => {
            console.log('check auth', res);

        }).catch(err => {
            console.log('check auth', err);
            localStorage.clear();
            if (!!props && !!props.history) {
                props.history.push('/');
            }
            else {
                window.location.reload(false);
            }
        });

    }

    login = (params) => {
        return this.init().post("/auth/emp-login", params);
    };

    getLicenceInfo = (body) => {
        return this.init().get('/get/licenceInfo', { params: body });
    }

    getChapters = (body) => {
        return this.init().get('/get/empmodules', { params: body });
    }
    getTopics = (body) => {
        return this.init().get('/get/emptopics', { params: body });
    }
    getContent = (body) => {
        return this.init().get('/get/empcontent', { params: body });
    }
    updateReadTime = (body) => {
        return this.init().get('/update/readTime', { params: body });
    }
    getQuiz = (body) => {
        return this.init().get('/fetchQuiz', { params: body });
    }
    saveQuiz = (body) => {
        return this.init().post('/save/attempt', body);
    }
    getQuizDetails = (body) => {
        return this.init().get('/get/attempt', { params: body });
    }
    generateCertificate = (body) => {
        return this.init().post('/create/certificate', body);
    }
    submitQuery = async (data) => {
        return this.init().post('/post/query', data);
    }


}
