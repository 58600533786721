// Chakra imports
import {
  ChakraProvider, Box, Image, Flex, Text, Icon, Button, Link, Menu, IconButton,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";

import React, { useState, useEffect } from "react";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import routes from "routes.js";
import pageRoutes from "pageRountes.js";
import theme from "theme/theme.js";
import MainPanel from "../components/Layout/MainPanel";
import PanelContainer from "../components/Layout/PanelContainer";
import PanelContent from "../components/Layout/PanelContent";
import Api from "../helpers/Fetch";
import signInImage from 'assets/img/C4P-logo-1.png'
import { MdPerson } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";
import { BiLogOut } from "react-icons/bi";

export default function Dashboard(props) {
  const { ...rest } = props;
  let api = new Api();
  const [data, setData] = useState(null);
  useEffect(() => {
    api.checkAuthToken(props);
    getLicenceInfo("english");
  }, []);
  const getLicenceInfo = (lang) => {
    api.getLicenceInfo({ lang: lang }).then((res) => {
      console.log(res.data);
      setData(res.data.data);
    }).then((err) => {
      console.log(err);
    })
  }
  // states and functions
  const [sidebarVariant, setSidebarVariant] = useState("transparent");
  const [fixed, setFixed] = useState(false);
  // ref for main panel div
  const mainPanel = React.createRef();
  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].views);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.category === "account") {
        return getRoutes(prop.views);
      }
      if (prop.layout === "") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getPageRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getPageRoutes(prop.views);
      }
      if (prop.category === "account") {
        return getPageRoutes(prop.views);
      }
      if (prop.layout === "") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  return (
    <ChakraProvider theme={theme} resetCss={false}>
      <Flex justifyContent='center' bg='transparent'>
        <Box w='100%' boxShadow='sm' h='70px' bgColor='white' borderBottomStartRadius='20px' borderBottomEndRadius="20px">
          <Flex justify='space-between' justifyItems='center' align='center'>
            <NavLink to='/'>
              <Image
                boxSize='70px'
                objectFit='contain'
                src={signInImage}
                opacity={0.9}
                ms='3'
                alt='C4P Logo'
              />
            </NavLink>
            <Flex direction='column' textAlign='center'>
              <Text fontWeight='bold' fontSize='md' color='gray.400'>Licence</Text>
              <Text fontWeight='bold' fontSize='lg' color='gray.900'>{!!data ? data.licence : ''}</Text>
            </Flex>
            <Flex>
              <NavLink to='/support'>
                Need Help?
              </NavLink>
              <Link me='5'>
                {/* <Icon as={FaUserCircle} w={8} h={8} color='red.300' me='5' /> */}
                <Menu size='md'>
                  <MenuButton
                    as={IconButton}
                    aria-label='Options'
                    color='red.300'
                    icon={<FaUserCircle w={"64px"} h={"64px"} />}
                    variant='link'
                  />
                  <MenuList>
                    <Text p={2} color='gray.600' fontSize='sm' ms='2'>{!!data ? data.companyName : ''}</Text>
                    <Text p={2} ms='2' fontStyle='italic' color='gray.400'>{!!data ? 'Expiry: ' + data.licenceExpiryDate : ''}</Text>
                    <MenuItem icon={<BiLogOut />} onClick={() => { localStorage.clear(); window.location.reload(false) }}>
                      Logout
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Link>
            </Flex>

          </Flex>
        </Box>
      </Flex>
      <MainPanel
        ref={mainPanel}
        w={{
          base: "100%",
          xl: "calc(100%)",
        }}
      >
        {getRoute() ? (
          <PanelContent>
            <PanelContainer>
              <Switch>
                {getRoutes(routes)}
                {getPageRoutes(pageRoutes)}
                <Redirect from="/" to="/dashboard" />
              </Switch>
            </PanelContainer>
          </PanelContent>
        ) : null}
      </MainPanel>
    </ChakraProvider>
  );
}
