// import
import Dashboard from "views/Dashboard/Dashboard.js";

import Chapters from "views/Pages/chapters.js";
import Topics from "views/Pages/topics.js";
import Content from "views/Pages/content.js";
import Quiz from "views/Pages/quiz.js";
import QuizResult from "views/Pages/quiz_result";
import Support from "views/Pages/Support";

import {
  HomeIcon,
} from "components/Icons/Icons";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "",
  },
  {
    path: "/c/view",
    name: "Chapters",
    rtlName: "",
    icon: <HomeIcon color="inherit" />,
    component: Chapters,
    layout: "",
  },
  {
    path: "/t/view",
    name: "Topics",
    rtlName: "",
    icon: <HomeIcon color="inherit" />,
    component: Topics,
    layout: "",
  },
  {
    path: "/view/content",
    name: "Content",
    rtlName: "",
    icon: <HomeIcon color="inherit" />,
    component: Content,
    layout: "",
  },
  {
    path: "/run/quiz",
    name: "Quiz",
    rtlName: "",
    icon: <HomeIcon color="inherit" />,
    component: Quiz,
    layout: "",
  },
  {
    path: "/quiz/result",
    name: "Quiz Result",
    rtlName: "",
    icon: <HomeIcon color="inherit" />,
    component: QuizResult,
    layout: "",
  },
  {
    path: "/support",
    name: "Help",
    rtlName: "",
    icon: <HomeIcon color="inherit" />,
    component: Support,
    layout: "",
  }
];
export default dashRoutes;
