import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
// Chakra imports
import {
  Box,
  Flex,
  Button,
  Image,
  FormControl,
  FormLabel,
  Heading,
  Input, InputGroup, InputLeftElement,
  Text,
  useToast,
  useColorModeValue,
} from '@chakra-ui/react'
// Assets
import signInImage from 'assets/img/C4P-logo-1.png'
import signInBg from 'assets/img/posh.png'
import background from 'assets/img/bg_login.jpeg'
import Api from '../../helpers/Fetch'
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { BiKey, BiUser } from 'react-icons/bi';

function SignInEmp(props) {
  // Chakra color mode
  const titleColor = useColorModeValue('red.500', 'red.200')
  const textColor = useColorModeValue('gray.400', 'white')
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();

  function doLogin(data) {
    setError(null)
    setLoading(true)
    console.log('login clicked')
    const api = new Api()


    api
      .login(data)
      .then((res) => {
        setLoading(false)
        console.log(res);
        localStorage.setItem("ACCESS_TOKEN", res.data.data.token);
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("userType", 'E');
        if (res.data.data.role === 'employee') {
          localStorage.setItem("userType", 'E');
        }
        toast({
          title: 'LoggedIn',
          description: res.data.message,
          status: 'success',
          duration: 1000,
          isClosable: true,
        })
        window.location.reload(false)
      })
      .catch((err) => {
        setLoading(false)
        if (!!err && !!err.response && !!err.response.data) {
          console.log(err.response.data)
          setError(err.response.data.message)
          toast({
            title: 'Denied',
            description: err.response.data.message,
            status: 'error',
            duration: 2000,
            isClosable: true,
          })

        }

      })
  }
  return (<Flex w='100%' h='100vh' objectFit='cover'>
    <Image
      boxSize='100%'
      objectFit='cover'
      src={background}
      opacity={0.2}
      pos='absolute'
    >
    </Image>

    <Card w={{ sm: '100%', md: '40%', lg: '40%' }} mt='10' mb='10' ms='-20px' me='10'>
      <Flex justifyContent='center' >
        <Image
          boxSize='100px'
          objectFit='contain'
          src={signInImage}
          opacity={0.9}
          alt='Dan Abramov'
        />
      </Flex>
      <Flex justifyContent='center'>
        <Box w='70%' textAlign='center'>
          <Text fontSize='xl' fontWeight='bold'>Employee Login</Text>
          <Formik
            initialValues={{ licence: '', clientId: '' }}
            onSubmit={(values, actions) => {
              doLogin(values);
            }}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,

            }) => (
              <form onSubmit={handleSubmit}>
                <FormControl>
                  <Box w='100%' mt='10'>
                    <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                      Licence Key
                    </FormLabel>
                    <InputGroup size='md'>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<BiKey color="gray.300" />}
                      />
                      <Input type="text" placeholder="xxx-xxx-xxx-xxx" name="licence" onChange={handleChange} required />
                    </InputGroup>

                    <FormLabel ms="4px" fontSize="sm" fontWeight="normal" mt='4' >
                      Client Id
                    </FormLabel>
                    <InputGroup size='md' mb='10'>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<BiUser color="gray.300" />}
                      />
                      <Input type="text" placeholder="client id" name="clientId" onChange={handleChange} required />
                    </InputGroup>

                    <Button
                      fontSize="10px"
                      type="submit"
                      bg="red.300"
                      w="100%"
                      h="45"
                      mb="20px"
                      color="white"
                      isLoading={isLoading}
                      mt="10px"
                      _hover={{
                        bg: 'red.200',
                      }}
                      _active={{
                        bg: 'red.400',
                      }}
                      type="submit"
                    >
                      SIGN IN
                    </Button>
                  </Box>

                </FormControl>
              </form>
            )}
          </Formik>
        </Box>
      </Flex>
    </Card>
    <Card w={{ md: '60%', lg: '60%', sm: '0%' }} p='0' mt='10' mb='10' me='-20px' overflow='hidden'>
      <Image
        boxSize='100%'
        objectFit='cover'
        src={signInBg}
        opacity={1.0}
      />
    </Card>

  </Flex>);

}

export default SignInEmp;
